export const initialValueState = {
  nameCompany: "",
  document: "",
  referenceId: "",
  validade: "",
  orderId: 0,
  dateCreated: "2024-07-02T08:05:50.358796",
  status: "Aguardando",
  seller: {
    name: "",
    contact: "",
  },
  client: {
    name: "",
    contact: "",
    email: "",
    clientCostumer: [
      {
        clientCustomerId: 0,
        address: "",
        number: "",
        state: "",
        city: "",
        zipCode: "",
        cityCode: null,
        complement: "",
        observation: null,
        type: null,
        priority: 0,
        clientId: 0,
        dateDeleted: "0001-01-01T00:00:00",
      },
    ],
  },
  products: [
    {
      orderProductId: 0,
      orderId: 0,
      productId: 0,
      name: "",
      quantity: 0,
      unitaryValue: 0,
      amount: 0,
      referenceId: "",
      sku: "",
      orderProductOption: [],
      dateCreated: "2024-07-02T08:05:50.358796",
      dateUpdated: "2024-07-02T08:05:50.358797",
      dateDeleted: "0001-01-01T00:00:00",
    },
  ],
  orderPyamnetTerm: [
    {
      orderPaymentTermId: 0,
      orderId: 0,
      paymentTermId: 0,
      name: null,
      type: false,
      referenceId: null,
      amount: 0,
    },
  ],
  obsInternal: ["", "", "", "", ""],
  totalOrder: {
    total: 0,
    totalDesconto: 0,
    subTotal: 0,
  },
};
